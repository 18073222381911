import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  ConceptMedia,
  CFreeCard,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                FREE Wi-Fi<br className="u_sp" />
                SERVICES
              </>
            ),
            sub: 'Wi-fi無料サービス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/business/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/business/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: 'Wi-fi無料サービス',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <p className="c_sectLead u_mb40">
            ロビー、客室、レストラン、宴会場にて無線LANが無料でご利用いただけます。
          </p>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: 'ホテル棟',
                text: (
                  <>
                    すべての客室
                    <br />
                    すべてのレストラン＆バー
                    <br />
                    1F　フロントロビー
                    <br />
                    1F　デリカ＆ラウンジ「コフレ」
                    <br />
                    49F ランドマークスパ
                  </>
                ),
              },
              {
                title: '宴会棟',
                text: (
                  <>
                    すべての宴会場
                    <br />
                    1F　宴会棟ロビー
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList u_mb40">
            <li>無線LAN規格：IEEE802.11b/g/n</li>
            <li>
              接続方法は、ご利用の際ホテルスタッフにお尋ねください。
            </li>
            <li>
              PC本体の貸出サービスはございませんので、あらかじめご了承ください。
            </li>
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
